import routingConfig from 'configs/routing';
import { put, select, takeEvery } from 'redux-saga/effects';

import new_api from 'utils/new-api';

import { convertApiErrorCodesToMessages } from 'helpers/app';

import apiEndpoints from 'configs/api/endpoints';

import { actions, TCodeTypeBrand } from './index';

import { convertPathUsingParams, Directions } from '@rfb/common';
import { IAttachmentUploadResult } from '@rfb/common/lib/types/interfaces/attachment-upload-result';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import {
  BrandListItemDto,
  DealerBrandCode,
  LimitStatus,
  PaymentDataListItemDto,
  RepoRequestApplyStatus,
  RepoRequestDataEditForm,
  RepoRequestDataRecordDto,
  RequestDataEditForm,
  RequestDataUsedEditForm,
  TBrandModel,
  TDealerBrand,
  TFinancingRepoRequestDataFinancingInfoResponse,
  TRepoModel,
  TRepoRequestDataBrandsResponse,
  TRepoRequestDataResponse,
  TRepoRequestTypesResponse,
} from 'dto/financial-block';
import { TRepoAttachment } from 'dto/operation-data';
import {
  FinancingRequestDataResponse,
  FinancingRequestDataUsedResponse,
  FinancingRequestDTO,
  IDTORFInfoHeaders,
} from 'dto/rf-info';
import fp from 'lodash/fp';
import notification from 'utils/notification';
import { TGeneratedFile } from '../components/FTTableRepo/component';
import { getReceiverOptionValueAndLabel } from '../helpers';
import { IFinancialState, RbAutoType, RepoAutoType, TFormPrefilling } from './slice';

type TAttachment = {
  attachment_id: number;
  file_name: string;
  type_code: string;
};

function* getRequestData(action: TODO_ANY) {
  const filter: IFinancialState['filter'] = action.payload;
  try {
    yield put(actions.set({ requestData: [], pageCount: 0, isLoading: true }));
    yield put(actions.setError({ rfInfo: [] }));
    yield put(actions.setError({ api: [] }));
    const url: string =
      apiEndpoints.financialBlock.get +
      `?page=${filter.page}` +
      `&sort=${fp.isEqual(Directions.DESC, filter.sorting?.direction) ? '-' : ''}${
        filter.sorting?.value
      }&per-page=${filter.perPage}`;
    const response: {
      data: {
        status: String;
        data: FinancingRequestDataResponse;
      };
      headers: IDTORFInfoHeaders;
    } = yield new_api.get(url);
    const result = response.data;
    if (result.status === 'OK') {
      result.data = {
        ...result.data,
        records: result.data?.records.map((record) => ({
          ...record,
          request_invoice: `${
            process.env.REACT_APP_API_URL
          }${apiEndpoints.financialBlock.fileDownload.replace(
            ':id',
            String(record.request_data_id)
          )}`,
        })),
      };
      yield put(actions.getRequestDataSuccessful({ data: result.data, headers: response.headers }));
    } else {
      onError(`Ошибка получения списка пользователей: ${JSON.stringify(result.status)}`);
    }
  } catch (error) {
    yield put(actions.getRequestDataFailure(error));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* getRequestDataSuccessful(action: TODO_ANY) {
  const isRequestIncludePorsche = action.payload.data.records.some(
    (element: FinancingRequestDTO) => element.request_brand.toUpperCase() === 'PORSCHE'
  );
  yield put(
    actions.set({
      requestData: action.payload.data.records,
      totalSum: action.payload.data.request_sum,
      pageCount: fp.toNumber(action.payload.headers['x-pagination-page-count']),
      totalCount: fp.toNumber(action.payload.headers['x-pagination-total-count']),
      isRequestIncludePorsche,
    })
  );
}

function* getUsedCarSettings(action: TODO_ANY) {
  yield put(actions.setError({ rfInfo: [], isLoading: true }));
  try {
    const response: {
      data: TODO_ANY;
    } = yield new_api.get(apiEndpoints.usedCarSettings.getUsedCarSettings);
    const result = response.data;
    const usedCarSettings = result.data.text ?? {
      max_age: 15,
      max_run: 200000,
      max_cost: 12000000,
    };
    yield put(actions.set({ usedCarSettings: usedCarSettings }));
  } catch (error) {
    yield put(actions.getRequestDataFailure(error));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* getRequestDataUsed(action: TODO_ANY) {
  const filter: IFinancialState['filter'] = action.payload;
  try {
    yield put(actions.set({ requestData: [], pageCount: 0, isLoading: true }));
    yield put(actions.setError({ rfInfo: [] }));
    const url: string =
      apiEndpoints.financialBlock.getUsed +
      `?page=${filter.page}` +
      `&sort=${fp.isEqual(Directions.DESC, filter.sorting?.direction) ? '-' : ''}${
        filter.sorting?.value
      }&per-page=${filter.perPage}`;
    const result: {
      data: FinancingRequestDataUsedResponse;
      headers: IDTORFInfoHeaders;
    } = yield new_api.get(url);
    result.data = {
      ...result.data,
      records: result.data?.records.map((record) => {
        const res = {
          ...record,
          request_invoice: `${
            process.env.REACT_APP_API_URL
          }${apiEndpoints.financialBlock.fileDownload.replace(
            ':id',
            String(record.request_data_id)
          )}`,
        };
        return res;
      }),
    };
    yield put(actions.getRequestDataUsedSuccessful({ data: result.data, headers: result.headers }));
  } catch (error) {
    yield put(actions.getRequestDataFailure(error));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* getRequestDataUsedSuccessful(action: TODO_ANY) {
  yield put(
    actions.set({
      requestData: action.payload.data.records,
      totalSum: action.payload.data.request_sum,
      pageCount: fp.toNumber(action.payload.headers['x-pagination-page-count']),
      totalCount: fp.toNumber(action.payload.headers['x-pagination-total-count']),
    })
  );
}

function* getRequestDataFailure() {
  yield put(actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] }));
}

function* getDealerBrandCodes() {
  console.log(`//sagas/getDealerBrandCodes BEGIN`);
  try {
    yield put(actions.set({ isLoading: true }));
    const result: { data: DealerBrandCode[] } = yield new_api.get(apiEndpoints.rfInfo.code);
    yield put(
      actions.getDealerBrandCodesSuccessful({
        codes: result.data,
      })
    );
  } catch (error) {
    console.log(`//sagas/getDealerBrandCodes ERROR: ${JSON.stringify(error)}`);
    if (error.response.status === 404) {
      yield put(
        actions.getDealerBrandCodesSuccessful({
          codes: null,
        })
      );
      return;
    }

    yield put(actions.getDealerBrandCodesFailure(error));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
  console.log(`//sagas/getDealerBrandCodes END`);
}

function* getDealerBrandCodesSuccessful(action: TODO_ANY) {
  let codeTypeBrand: TCodeTypeBrand;
  let codeType: RbAutoType;
  let isNoCreditLines: boolean = false;
  if (!action.payload.codes || action.payload.codes.length === 0) {
    codeTypeBrand = 'none';
    // codeType = CodeTypeUsed.NONE;
    codeType = RbAutoType.NEW_ONLY; // TODO Удалить времянку, нормально поддержав NONE
    isNoCreditLines = true;
  } else {
    // codeTypeBrand
    codeTypeBrand = 'both';
    if (action.payload.codes?.every((el: DealerBrandCode) => el.brand === 'Porsche')) {
      codeTypeBrand = 'only-porsche';
    } else if (action.payload.codes?.every((el: DealerBrandCode) => el.brand !== 'Porsche')) {
      codeTypeBrand = 'only-non-porsche';
    }
    // codeType
    if (action.payload.codes?.every((el: DealerBrandCode) => el.product_code === '930')) {
      codeType = RbAutoType.USED_ONLY;
    } else if (action.payload.codes?.every((el: DealerBrandCode) => el.product_code !== '930')) {
      codeType = RbAutoType.NEW_ONLY;
    } else {
      codeType = RbAutoType.MIXED;
    }
  }

  yield put(
    actions.set({
      codes: action.payload.codes,
      codeTypeBrand,
      codeTypeUsed: codeType,
      isNoCreditLines,
    })
  );
}

function* getDealerBrandCodesFailure() {
  yield put(actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] }));
}

function* getRequestCodeOptions(action: TODO_ANY) {
  console.log(`//sagas/getRequestCodeOptions BEGIN: ${JSON.stringify(action.payload)}`);
  try {
    yield put(actions.set({ isLoading: true }));
    const response: { data: DealerBrandCode[] } = yield new_api.get(apiEndpoints.rfInfo.code, {
      // params: { brand_type: action.payload.brand_type },
    });
    console.log(`//sagas/getRequestCodeOptions: result = ${JSON.stringify(response)}`);
    yield put(
      actions.getRequestCodeOptionsSuccessful({
        codes: response.data,
        used: action.payload.used,
      })
    );
  } catch (error) {
    console.log(`//sagas/getRequestCodeOptions ERROR: ${JSON.stringify(error)}`);
    yield put(actions.getRequestCodeOptionsFailure(error));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
  console.log(`//sagas/getRequestCodeOptions END`);
}

function* getRequestCodeOptionsSuccessful(action: TODO_ANY) {
  const newCodes = action.payload.used
    ? action.payload.codes
    : action.payload.codes.filter((item: TODO_ANY) => item.product_code !== '930');
  const resultOptions = newCodes?.map((code: any) => ({
    label: `${code.code} (${code.brand})`,
    value: code.code,
  }));
  yield put(
    actions.set({
      requestCodeOptions: resultOptions,
    })
  );
}

function* getRequestCodeOptionsFailure() {
  yield put(actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] }));
}

function* getBrandOptions() {
  try {
    yield put(actions.setError({ api: [] }));
    yield put(actions.set({ isLoading: true }));
    const url: string = apiEndpoints.rfInfo.brands + `?flagUsed=1`;
    const result: { data: TDealerBrand[] } = yield new_api.get(url);
    yield put(
      actions.getBrandOptionsSuccessful({
        brands: result.data,
      })
    );
  } catch (error) {
    yield put(actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] }));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* getBrandOptionsSuccessful(action: TODO_ANY) {
  yield put(
    actions.set({
      usedBrandOptions: action.payload.brands.map((item: TDealerBrand) => ({
        label: item.brand_name,
        value: item.brand_id,
      })),
    })
  );
}

function* getBrandModelOptions(action: TODO_ANY) {
  try {
    yield put(actions.setError({ api: [] }));
    yield put(actions.set({ isLoading: true }));
    const url =
      convertPathUsingParams(apiEndpoints.rfInfo.models, {
        brandId: action.payload,
      }) + `?flagUsed=1`;
    const result: { data: TBrandModel[] } = yield new_api.get(url);
    yield put(
      actions.getBrandModelOptionsSuccessful({
        models: result.data,
      })
    );
  } catch (error) {
    yield put(actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] }));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* getBrandModelOptionsSuccessful(action: TODO_ANY) {
  const modelOptions = action.payload.models.map((item: TBrandModel) => ({
    value: item.model_id,
    label: item.model_name,
  }));
  yield put(
    actions.set({
      usedBrandModelOptions: modelOptions,
    })
  );
}

function* createRequestData(action: TODO_ANY) {
  try {
    yield put(actions.set({ isLoading: true }));
    yield put(actions.setError({ api: [] }));
    const data: RequestDataEditForm = action.payload.data;
    let formdata = new FormData();
    const pdfFile: any = data.pdfFile;
    delete data.pdfFile;

    formdata.append('brand', data.brand as string);
    formdata.append('code', data.code as string);
    formdata.append('vin', data.vin as string);
    formdata.append('amount', data.amount as string);
    if (action.payload.brand_type === 'PORSCHE') {
      formdata.append('file', pdfFile);
    }
    yield new_api.post(apiEndpoints.financialBlock.post, formdata, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    yield put(actions.createRequestDataSuccessful({ onSuccess: action.payload.onSuccess }));
  } catch (error) {
    yield put(actions.createRequestDataFailure(error.response?.data));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* createRequestDataSuccessful(action: TODO_ANY) {
  if (action.payload.onSuccess) {
    yield action.payload.onSuccess();
  }
  notification.info('Автомобиль успешно добавлен');
}

function* createRequestDataFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload) }));
}

enum FileType {
  CONTRACT = 'CON',
  ACT = 'ACT',
  RECEIPT = 'REC',
}

type FileForUpload = {
  file?: File;
  fileType?: FileType;
  fileId?: string;
};

async function putUpload(
  uploadResult: IAttachmentUploadResult[],
  requestDataId: number,
  files: FileForUpload[]
) {
  const fetchPromises = files.map(async (fileForUpload) => {
    if (fileForUpload.file) {
      const errMessage = `Файл ${fileForUpload.file.name} выгрузить не удалось`;
      try {
        const formData = new FormData();
        formData.append('file', fileForUpload.file);
        const url: string = `${convertPathUsingParams(apiEndpoints.financialBlock.putFileUpload, {
          id: requestDataId,
          attachmentId: fileForUpload.fileId!,
        })}?file_name=${fileForUpload.file.name}`;
        const response = await new_api.put(url, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        const result = response.data;
        if (result.status === 'OK') {
          uploadResult.push(response.data);
        } else if (result.status === 'SIZE_EXCEEDED') {
          console.error(`${errMessage}, status: ${result.status}`);
          notification.err(`Размер файла вложения "${fileForUpload.file.name}" превышает 5 Мб`);
        } else {
          console.error(`${errMessage}, status: ${result.status}`);
          notification.info(errMessage);
        }
      } catch (error) {
        notification.info(errMessage);
      }
    }
  });
  return uploadResult;
}

function* createRequestDataUsed(action: TODO_ANY) {
  try {
    yield put(actions.set({ isLoading: true }));
    yield put(actions.setError({ api: [] }));

    const data: RequestDataUsedEditForm = action.payload.data;
    const formData = new FormData();

    const record: TODO_ANY = {};
    record.brand = data.brand;
    record.brand_id = data.brand_id;
    record.model = data.model;
    record.model_id = data.model_id;
    record.engine_volume = data.engine_volume;
    record.engine_power = data.engine_power;
    record.electrical_engine_power = data.electrical_engine_power;
    record.car_run = data.car_run;
    record.car_passport_date = data.car_passport_date;
    record.vin = data.vin;
    record.not_vin = data.not_vin;
    record.amount = data.amount;

    const requestAttachments: TAttachment[] = [];
    if (data.contractPdf) {
      requestAttachments.push({
        attachment_id: 0,
        file_name: data.contractPdf.name,
        type_code: FileType.CONTRACT,
      });
    } else {
      throw new Error('Не задан файл договора купли-продажи');
    }
    if (data.actOfAcceptancePdf) {
      requestAttachments.push({
        attachment_id: 0,
        file_name: data.actOfAcceptancePdf.name,
        type_code: FileType.ACT,
      });
    } else {
      throw new Error('Не задан файл акта приёма-передачи');
    }
    if (data.proofOfPaymentPdf) {
      requestAttachments.push({
        attachment_id: 0,
        file_name: data.proofOfPaymentPdf.name,
        type_code: FileType.RECEIPT,
      });
    } else {
      throw new Error('Не задан файл документа, подтверждающего оплату');
    }

    record.request_attachments = requestAttachments;
    formData.append('record', new Blob([JSON.stringify(record)], { type: 'application/json' }));
    formData.append('attachment_act', data.contractPdf);
    formData.append('attachment_con', data.actOfAcceptancePdf);
    formData.append('attachment_rec', data.proofOfPaymentPdf);

    const response = yield new_api.post(apiEndpoints.financialBlock.postUsed, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    const result = response.data;
    if (result.status === 'OK') {
      action.payload.onSuccess && action.payload.onSuccess();
      notification.info('Автомобиль успешно добавлен');
    } else if (result.status === 'SIZE_EXCEEDED') {
      const errMessage = `Ошибка создания заявки: размер файла вложения превышает 5 Мб`;
      console.error(errMessage);
      notification.err(errMessage);
    } else {
      const errMessage = `Ошибка создания заявки. Статус: ${result.status}. Обратитесь в поддержку`;
      console.error(errMessage);
      notification.err(errMessage);
    }
  } catch (error) {
    throw { error, actions };
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* updateRequestData(action: TODO_ANY) {
  try {
    yield put(actions.set({ isLoading: true }));
    yield put(actions.setError({ api: [] }));
    const data: RequestDataEditForm = action.payload.data;
    let formdata = new FormData();
    const pdfFile: any = data.pdfFile;
    delete data.pdfFile;

    formdata.append('brand', data.brand as string);
    formdata.append('code', data.code as string);
    formdata.append('vin', data.vin as string);
    formdata.append('amount', data.amount as string);
    if (action.payload.brand_type === 'PORSCHE') {
      formdata.append('file', pdfFile);
    }
    yield new_api.put(
      apiEndpoints.financialBlock.put + '/' + action.payload.requestDataId,
      formdata,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    yield put(actions.updateRequestDataSuccessful({ onSuccess: action.payload.onSuccess }));
  } catch (error) {
    yield put(actions.updateRequestDataFailure(error));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* updateRequestDataUsed(action: TODO_ANY) {
  try {
    yield put(actions.set({ isLoading: true }));
    yield put(actions.setError({ api: [] }));
    const data: RequestDataUsedEditForm = action.payload.data;
    const url = convertPathUsingParams(apiEndpoints.financialBlock.putUsed, {
      id: action.payload.requestDataId,
    });
    yield new_api.put(url, {
      brand: data.brand,
      model: data.model,
      brand_id: data.brand_id,
      model_id: data.model_id,
      engine_volume: data.engine_volume,
      engine_power: data.engine_power,
      electrical_engine_power: data.electrical_engine_power,
      car_run: data.car_run,
      car_passport_date: data.car_passport_date,
      vin: data.vin,
      notVin: data.not_vin,
      amount: data.amount,
    });

    let filesForUpload = [];
    if (data.contractPdf)
      filesForUpload.push({
        file: data.contractPdf,
        fileId: data.contractPdfId,
      });
    if (data.actOfAcceptancePdf)
      filesForUpload.push({
        file: data.actOfAcceptancePdf,
        fileId: data.actOfAcceptancePdfId,
      });
    if (data.proofOfPaymentPdf)
      filesForUpload.push({
        file: data.proofOfPaymentPdf,
        fileId: data.proofOfPaymentPdfId,
      });

    const uploadResult: IAttachmentUploadResult[] = [];
    yield putUpload(uploadResult, action.payload.requestDataId, filesForUpload);

    yield put(actions.updateRequestDataSuccessful({ onSuccess: action.payload.onSuccess }));
  } catch (error) {
    yield put(actions.updateRequestDataFailure(error));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* updateRequestDataSuccessful(action: TODO_ANY) {
  if (action.payload.onSuccess) {
    yield action.payload.onSuccess();
  }
  notification.info('Автомобиль успешно отредактирован');
}

function* updateRequestDataFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload) }));
  notification.info('Сервер временно недоступен. Повторите попытку позднее');
}

function* deleteRequestData(action: TODO_ANY) {
  try {
    yield put(actions.set({ isLoading: true }));
    yield put(actions.setError({ api: [] }));
    yield new_api.delete(
      apiEndpoints.financialBlock.delete + '/' + action.payload.requestDataId,
      {}
    );
    yield put(actions.deleteRequestDataSuccessful({ onSuccess: action.payload.onSuccess }));
  } catch (error) {
    notification.info('Сервер временно недоступен. Повторите попытку позднее');
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* deleteRequestDataUsed(action: TODO_ANY) {
  try {
    yield put(actions.set({ isLoading: true }));
    yield put(actions.setError({ api: [] }));
    const url = convertPathUsingParams(apiEndpoints.financialBlock.deleteUsed, {
      id: action.payload.requestDataId,
    });
    yield new_api.delete(url, {});
    yield put(actions.deleteRequestDataSuccessful({ onSuccess: action.payload.onSuccess }));
  } catch (error) {
    notification.info('Сервер временно недоступен. Повторите попытку позднее');
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* deleteRequestDataSuccessful(action: TODO_ANY) {
  if (action.payload.onSuccess) {
    yield action.payload.onSuccess();
  }
  notification.info('Транш удален');
}

function* docRequest(action: TODO_ANY) {
  const isUsed = action.payload.isUsed;
  try {
    yield put(actions.set({ isFinancingRequestInProgress: true }));
    const response: { data: any } = isUsed
      ? yield new_api.post(apiEndpoints.financialBlock.docRequestUsed, {})
      : yield new_api.post(apiEndpoints.financialBlock.docRequest, {});
    yield put(actions.set({ ...response.data }));
    yield put(
      actions.docRequestSuccessful({
        isUsed,
        ...action.payload,
        ...response.data,
        onSuccess: action.payload.onSuccess,
      })
    );
  } catch (error) {
    yield put(actions.docRequestFailure(error));
  }
}

function* docRequestSuccessful(action: TODO_ANY) {
  yield put(actions.tranchesRequest({ ...action.payload }));
  action.payload.onSuccess && action.payload.onSuccess();
}

function* docRequestFailure(action: TODO_ANY) {
  notification.info('Сервер временно недоступен. Повторите попытку позднее');
  yield put(actions.set({ isFinancingRequestInProgress: false }));
  console.error(action.payload);
}

function* docDownload(action: TODO_ANY) {
  try {
    const state: {
      'financial-block': { pdf_file_name: string; filter: IFinancialState['filter'] };
    } = yield select();
    const response: { data: any } = yield new_api.get(
      `${apiEndpoints.financialBlock.docDownload}?filename=${state['financial-block'].pdf_file_name}`,
      {
        responseType: 'blob',
      }
    );
    // TODO Вынести в common
    let fileURL = window.URL.createObjectURL(response.data);
    window.open(fileURL, '_blank');
    yield put(actions.set({ pdf_file_name: undefined, doc_timestamp: undefined }));
    const backUrl = routingConfig.financingTranches;
    action.payload.history.push(backUrl);
    yield put(actions.getRequestData({ ...state['financial-block'].filter, page: 1 }));
  } catch (error) {
    yield put(actions.docRequestFailure(error));
  }
}

function* docDownloadUsed(action: TODO_ANY) {
  try {
    const state: {
      'financial-block': { pdf_file_name: string; filter: IFinancialState['filter'] };
    } = yield select();
    const url = `${apiEndpoints.financialBlock.docDownloadUsed}?filename=${state['financial-block'].pdf_file_name}`;
    const response: { data: any } = yield new_api.get(url, {
      responseType: 'blob',
    });
    // TODO Вынести в common
    let fileURL = window.URL.createObjectURL(response.data);
    window.open(fileURL, '_blank');
    yield put(actions.set({ pdf_file_name: undefined, doc_timestamp: undefined }));
    const backUrl = routingConfig.financialBlockFinancialBlockPicker.path + '/list/rb/used';
    action.payload.history.push(backUrl);
    yield put(actions.getRequestData({ ...state['financial-block'].filter, page: 1 }));
  } catch (error) {
    yield put(actions.docRequestFailure(error));
  }
}

function* tranchesRequest(action: TODO_ANY) {
  try {
    const state: {
      'financial-block': { totalSum: number };
    } = yield select();
    const { history, isUsed, onSuccess, ...payload } = action.payload; // TODO onSuccess не нужен, здесь просто убран из ...payload
    isUsed
      ? yield new_api.post(
          apiEndpoints.financialBlock.operationDataRequestUsed,
          {},
          { params: { ...payload, total_repayment: state['financial-block'].totalSum } }
        )
      : yield new_api.post(
          apiEndpoints.rfInfo.tranchesRequest,
          {},
          { params: { ...payload, total_repayment_amount: state['financial-block'].totalSum } }
        );
    yield put(
      actions.tranchesRequestSuccessful({ isUsed, history, brand_type: payload.brand_type })
    );
  } catch (error) {
    yield put(actions.tranchesRequestFailure(error));
  } finally {
    yield put(actions.set({ isFinancingRequestInProgress: false }));
  }
}

function getBackUrl(location: TODO_ANY, used: boolean) {
  const path = location.pathname.includes('/list/rb')
    ? location.pathname
    : location.pathname + '/list/rb';
  let search = location.search ? location.search + '&' : '?';
  search = search.includes('code_type') ? search : `${search}code_type=${used ? 'used' : 'new'}`;
  return `${path + search}&state=DOC`;
}

function* tranchesRequestSuccessful(action: TODO_ANY) {
  yield put(actions.set({ isRequestIncludePorsche: false }));
  const backUrl = getBackUrl(action.payload.history.location, action.payload.isUsed);
  action.payload.history.push(backUrl);
  notification.info('Запрос на кредитную заявку отправлен');
  yield;
}

function* tranchesRequestFailure() {
  notification.info('Сервер временно недоступен. Повторите попытку позднее');
  yield;
}

function* getRequestFileBody(action: TODO_ANY) {
  try {
    const response: { data: any } = yield new_api.get(action.payload.requestInvoice, {
      responseType: 'blob',
    });
    let fileURL = window.URL.createObjectURL(
      new Blob([response.data], { type: 'application/pdf' })
    );
    window.open(fileURL, '_blank');
  } catch (error) {
    yield put(actions.docRequestFailure(error));
  }
}

// TODO Устранить дублирование с notification
const extractFilenameFromContentDisposition = (disposition: string) => {
  let filename = '';
  if (disposition && disposition.startsWith('attachment')) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
};

// TODO Устранить дублирование с notification
function* attachmentDownload(action: TODO_ANY) {
  yield put(actions.setError({ api: [] }));
  const endpoint = apiEndpoints.financialBlock.attachmentDownload;
  const url: string = convertPathUsingParams(endpoint, {
    id: action.payload.id,
    attachmentId: action.payload.attachment_id,
  });
  const config = {
    responseType: 'blob',
  };
  try {
    const response = yield new_api.get(url, config);
    const contentDisposition = response.headers['content-disposition'];
    const filename = extractFilenameFromContentDisposition(contentDisposition);
    const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = action.payload.filename ?? filename;
    document.body.appendChild(link);
    link.click();
    // window.open(fileUrl, '_blank');
  } catch (error) {
    yield put(
      actions.setError({ api: ['Документ недоступен. Попробуйте повторить попытку позднее'] })
    );
  }
}

// РЕПО
function* getRepoRequestData(action: TODO_ANY) {
  const filter: IFinancialState['filter'] = action.payload;
  try {
    yield put(actions.set({ requestData: [], pageCount: 0, isLoading: true }));
    yield put(actions.setError({ rfInfo: [] }));
    yield put(actions.setError({ api: [] }));
    const { history, repoRequestTypeCode } = action.payload;
    let url: string = apiEndpoints.financialBlock.getRepoRequestData + '?';
    url += repoRequestTypeCode ? `repo_request_type_code=${repoRequestTypeCode}&` : '';
    url +=
      `page=${filter.page}` +
      `&sort=${fp.isEqual(Directions.DESC, filter.sorting?.direction) ? '-' : ''}${
        filter.sorting?.value
      }&per-page=${filter.perPage}`;
    const response: {
      data: TRepoRequestDataResponse;
      headers: IDTORFInfoHeaders;
    } = yield new_api.get(url);
    yield put(
      actions.getRepoRequestDataSuccessful({
        data: response.data.data,
        headers: response.headers,
        onSuccess: action.payload.onSuccess,
      })
    );
  } catch (error) {
    yield put(actions.getRepoRequestDataFailure(error));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function getFormPrefilling(
  record: RepoRequestDataRecordDto,
  receiverPaymentData: PaymentDataListItemDto
): TFormPrefilling {
  const result: TFormPrefilling = {
    brand: {
      value: record.brand_code,
      label: record.brand_name,
      isMultiBrand: record.multi_brand,
    },
    requestModel: record.model_name,
    requestModelId: record.model_id,
    originCountry: record.origin_country,
    deliveryLocation: record.delivery_location,
    recieverShortName: record.reciever,
    requestBrandCode: record.brand_code,
    receiverPaymentData,
  };
  return result;
}

function* getRepoRequestDataSuccessful(action: TODO_ANY) {
  const data = action.payload.data;
  const operationDataId = data?.records[0]?.operation_data_id;
  const headers = action.payload.headers;
  const totalCount = fp.toNumber(headers['x-pagination-total-count']);
  const totalSum = data.summary.request_sum;
  const advanceSum = data.summary.advance_sum;
  if (data.records.length === 0) {
    yield put(
      actions.set({
        repoRequestData: data,
        totalCount: 0, // TODO Убрать костыль - должно приходить из бека
        totalSum: 0, // TODO Убрать костыль - должно приходить из бека
        advanceSum: 0, // TODO Убрать костыль - должно приходить из бека
      })
    );
    // yield put(actions.resetRepoRequestData());
  } else {
    const lastRecord: RepoRequestDataRecordDto = data.records[0];
    // Получение реквизитов - receiverPaymentData
    let url: string = apiEndpoints.financialBlock.getRepoRequestDataFinancingInfo;
    url += `?brand_code=${lastRecord.brand_code}`;
    try {
      yield put(actions.set({ isLoading: true }));
      const response: TODO_ANY = yield new_api.get(url);
      const responseData: TFinancingRepoRequestDataFinancingInfoResponse = response.data;
      if (responseData.status === 'OK') {
        const paymentData: PaymentDataListItemDto[] = response.data.data.payment_data;
        const paymentDataIndex = paymentData.findIndex(
          (item) =>
            getReceiverOptionValueAndLabel(item.reciever_short_name, item.reciever_bank_name) ===
            lastRecord.reciever
        );
        let formPrefilling = null;
        if (paymentDataIndex >= 0) {
          formPrefilling = getFormPrefilling(lastRecord, paymentData[paymentDataIndex]);
        }
        yield put(
          actions.set({
            repoRequestData: data, // Тут есть избыточность, возможно, временная
            operationDataId,
            pageCount: fp.toNumber(headers['x-pagination-page-count']),
            totalSum,
            advanceSum,
            formPrefilling,
          })
        );
        action.payload.onSuccess && action.payload.onSuccess(operationDataId);
      } else {
        notification.err(`Что-то пошло не так, обратитесь в поддержку`);
      }
    } catch (error) {
      yield put(
        actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] })
      );
    } finally {
      yield put(actions.set({ isLoading: false }));
    }
  }
}

function* getRepoRequestDataFailure() {
  yield put(actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] }));
}

function* getRepoRequestDataById(action: TODO_ANY) {
  try {
    yield put(actions.setError({ api: [] }));
    yield put(actions.set({ isLoading: true }));
    // TODO Заменить реализацию
    const url: string = convertPathUsingParams(apiEndpoints.financialBlock.getRepoRequestDataById, {
      id: action.payload.id,
    });
    const result: {
      data: RepoRequestDataRecordDto;
    } = yield new_api.get(url);
    yield put(actions.getRepoRequestDataByIdSuccessful({ data: result.data }));
  } catch (error) {
    yield put(actions.getRepoRequestDataByIdFailure(error.response?.data));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* getRepoRequestDataByIdSuccessful(action: TODO_ANY) {
  yield put(actions.set({ currentNotification: action.payload.data }));
}

function* getRepoRequestDataByIdFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: [action.payload.message] }));
}

function* createRepoRequestData(action: TODO_ANY) {
  yield put(actions.set({ isLoading: true }));
  yield put(actions.setError({ api: [] }));
  const data: RepoRequestDataEditForm = action.payload.data;

  try {
    const url: string = apiEndpoints.financialBlock.postRepoRequestData;
    yield new_api.post(url, data);
    yield put(actions.createRepoRequestDataSuccessful({ onSuccess: action.payload.onSuccess }));
  } catch (error) {
    yield put(actions.createRepoRequestDataFailure(error.response?.data));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* createRepoRequestDataSuccessful(action: TODO_ANY) {
  if (action.payload.onSuccess) {
    yield action.payload.onSuccess();
  }
  notification.info('Автомобиль успешно добавлен');
}

function* createRepoRequestDataFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload) }));
}

function* deleteRepoRequestDataById(action: TODO_ANY) {
  try {
    yield put(actions.set({ isLoading: true }));
    yield put(actions.setError({ api: [] }));
    const url: string = `${convertPathUsingParams(
      apiEndpoints.financialBlock.deleteRepoRequestDataById,
      {
        id: action.payload.requestDataId,
      }
    )}`;
    yield new_api.delete(url);
    yield put(actions.deleteRepoRequestDataByIdSuccessful({ onSuccess: action.payload.onSuccess }));
  } catch (error) {
    notification.info('Сервер временно недоступен. Повторите попытку позднее');
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* deleteRepoRequestDataByIdSuccessful(action: TODO_ANY) {
  if (action.payload.onSuccess) {
    yield action.payload.onSuccess();
  }
  notification.info('Автомобиль удалён');
}

function* updateRepoRequestData(action: TODO_ANY) {
  try {
    yield put(actions.set({ isLoading: true }));
    yield put(actions.setError({ api: [] }));
    const data: RepoRequestDataEditForm = action.payload.data;
    const url: string = `${convertPathUsingParams(
      apiEndpoints.financialBlock.putRepoRequestDataById,
      {
        id: action.payload.requestDataId,
      }
    )}`;

    yield new_api.put(url, data);
    yield put(actions.updateRepoRequestDataSuccessful({ onSuccess: action.payload.onSuccess }));
  } catch (error) {
    yield put(actions.updateRequestDataFailure(error));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* updateRepoRequestDataSuccessful(action: TODO_ANY) {
  if (action.payload.onSuccess) {
    yield action.payload.onSuccess();
  }
  notification.info('Автомобиль успешно отредактирован');
}

function* updateRepoRequestDataFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload) }));
  notification.info('Сервер временно недоступен. Повторите попытку позднее');
}

function* getRepoRequestTypes(action: TODO_ANY) {
  const filter: IFinancialState['filter'] = action.payload;
  try {
    yield put(actions.set({ requestData: [], pageCount: 0, isLoading: true }));
    yield put(actions.setError({ rfInfo: [] }));
    yield put(actions.setError({ api: [] }));
    const url = apiEndpoints.financialBlock.getRepoRequestTypes;
    const result: {
      data: TRepoRequestTypesResponse;
      headers: IDTORFInfoHeaders;
    } = yield new_api.get(url);
    yield put(
      actions.getRepoRequestTypesSuccessful({ data: result.data, headers: result.headers })
    );
  } catch (error) {
    yield put(actions.getRepoRequestTypesFailure({ error, history: action.payload.history }));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* getRepoRequestTypesSuccessful(action: TODO_ANY) {
  yield put(
    actions.set({
      repoRequestTypes: action.payload.data,
      codeTypeUsed: RepoAutoType.MIXED, // Временный хардкод - пока жёстко и NEW, и DEMO
    })
  );
}

function* getRepoRequestTypesFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] }));
  const backUrl = '/financing-tranches'; // Возврат из зоны 'financing-tranches-cartype/rf' на 'financing-tranches'
  action.payload.history.push(backUrl);
}

function* getRepoRequestDataBrands(action: TODO_ANY) {
  const { repoRequestTypeCode } = action.payload;
  try {
    yield put(actions.setError({ rfInfo: [] }));
    yield put(actions.setError({ api: [] }));

    let url: string = apiEndpoints.financialBlock.getRepoRequestDataBrands + '?';
    url += repoRequestTypeCode ? `repo_request_type_code=${repoRequestTypeCode}` : '';

    const response: {
      data: TRepoRequestDataBrandsResponse;
      headers: IDTORFInfoHeaders;
    } = yield new_api.get(url);
    if (response.data.status === 'OK') {
      if (response.data.data.length !== 0) {
        yield put(actions.getRepoRequestDataBrandsSuccessful({ data: response.data.data }));
      } else {
        // yield put(actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] }));
        notification.info(`Получен пустой список марок, обратитесь в поддержку`);
      }
    } else if (response.data.status === 'INCOMPLETE_DATA') {
      notification.err(`Отсутствуют данные для финансирования по марке`);
    } else {
      notification.err(`Что-то пошло не так, обратитесь в поддержку`);
    }
  } catch (error) {
    yield put(actions.getRepoRequestDataBrandsFailure(error.response?.data));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* getRepoRequestDataBrandsSuccessful(action: TODO_ANY) {
  yield put(
    actions.set({
      repoRequestDataBrands: action.payload.data.map((item: BrandListItemDto) => ({
        label: item.brand_name,
        value: item.brand_code,
        isMultiBrand: item.multi_brand,
      })),
    })
  );
}

function* getRepoRequestDataBrandsFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload) }));
  // yield put(actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] }));
}

function* prepareRepoFinancing(action: TODO_ANY) {
  // По идее набор кнопок для РУСФИНАНС: 'Новые', 'Демо', ...
  // Пока используется хардкод - для пары кнопок 'Новые' и 'Демо', результат запроса getRepoRequestTypes во внимание не принимается
  yield put(actions.getRepoRequestTypes({ history: action.payload.history }));
}

function* getRepoRequestDataFinancingInfo(action: TODO_ANY) {
  const { history, brandCode } = action.payload;
  try {
    yield put(actions.set({ isLoading: true }));
    yield put(actions.setError({ rfInfo: [] }));
    yield put(actions.setError({ api: [] }));

    let url: string = apiEndpoints.financialBlock.getRepoRequestDataFinancingInfo;
    url += brandCode ? `?brand_code=${brandCode}` : '';
    const response: TODO_ANY = yield new_api.get(url);
    const responseData: TFinancingRepoRequestDataFinancingInfoResponse = response.data;
    if (responseData.status === 'OK') {
      yield put(
        actions.getRepoRequestDataFinancingInfoSuccessful({
          data: response.data,
          onSuccess: action.payload.onSuccess,
        })
      );
    } else {
      notification.err(`Что-то пошло не так, обратитесь в поддержку`);
    }
  } catch (error) {
    yield put(actions.getRepoRequestDataFinancingInfoFailure({ error, history }));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* getRepoRequestDataFinancingInfoSuccessful(action: TODO_ANY) {
  yield put(
    actions.set({
      lastFinancingRepoRequestDataFinancingInfoResponse: action.payload.data,
    })
  );
  yield put(
    actions.set({
      currentLimitAmount: action.payload.data.data.limit_amount,
      deliveryLocations: action.payload.data.data.delivery_locations,
      paymentDataList: action.payload.data.data.payment_data,
      advancePercent: action.payload.data.data.advance_percent,
      advancePercentDemo: action.payload.data.data.advance_percent_demo,
    })
  );
  if (action.payload.data.data) {
    yield put(
      actions.set({
        repoRequestDataModels: action.payload.data.data.models.map((item: TRepoModel) => ({
          label: item.model_name,
          value: item.model_id,
        })),
      })
    );
  }
  action.payload.onSuccess && action.payload.onSuccess(action.payload.data.data.payment_data);
}

function* getRepoRequestDataFinancingInfoFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] }));
  const backUrl = '/financing-tranches'; // Возврат из зоны 'financing-tranches-cartype/rf' на 'financing-tranches'
  action.payload.history.push(backUrl);
}

function* uploadForRepo(action: TODO_ANY) {
  try {
    yield put(actions.set({ isLoading: true }));
    const formData = new FormData();
    const files = action.payload.files;
    for (let i = 0; i < files.length; i++) {
      const blob = new Blob([files[i]], { type: 'application/pdf' });
      formData.append('files', blob, files[i].name);
    }

    const url: string = convertPathUsingParams(apiEndpoints.financialBlock.postRepoUpload, {
      operationDataId: action.payload.operationDataId,
    });
    const result = yield new_api.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (result.data.status === 'OK') {
      yield put(actions.uploadForRepoSuccessful({ result, onSuccess: action.payload.onSuccess }));
    } else if (result.data.status === 'SIZE_EXCEEDED') {
      notification.info('Превышен допустимый размер файла вложения');
    } else {
      yield put(actions.uploadForRepoFailure({}));
    }
  } catch (error) {
    yield put(actions.uploadForRepoFailure({}));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* uploadForRepoSuccessful(action: TODO_ANY) {
  action.payload.onSuccess && action.payload.onSuccess();
}

function* uploadForRepoFailure(action: TODO_ANY) {
  notification.info('Ошибка выгрузки. Проверьте корректность формата файла.');
  yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload) }));
  // yield put(actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] }));
}

function* addAttachments(action: TODO_ANY) {
  const files = action.payload.files;
  try {
    const response = yield put(
      actions.uploadForRepo({
        files,
        operationDataId: action.payload.operationDataId,
        onSuccess: () => {
          action.payload.onSuccess && action.payload.onSuccess();
        },
      })
    );
  } catch (error) {
    console.error(`//sagas/addAttachments: error = ${JSON.stringify(error)}`);
  }
}

function* deleteAttachment(action: TODO_ANY) {
  try {
    yield put(actions.set({ isLoading: true }));
    yield put(actions.setError({ api: [] }));
    const url = convertPathUsingParams(apiEndpoints.financialBlock.deleteRepoAttachment, {
      operationDataId: action.payload.operationDataId,
      operationAttachmentId: action.payload.operationAttachmentId,
    });
    const response = yield new_api.delete(url, {});
    yield put(actions.deleteAttachmentSuccessful({ onSuccess: action.payload.onSuccess }));
  } catch (error) {
    yield put(actions.deleteAttachmentFailure({}));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* deleteAttachmentSuccessful(action: TODO_ANY) {
  // Отображаем результаты удаления на фронте
  const fileNameToRemove: string = action.payload.filename;
  const state: {
    'financial-block': { repoAttachments: TODO_ANY };
  } = yield select();

  const oldAttachments = state['financial-block'].repoAttachments;
  const result = oldAttachments.filter((file: File) => file.name !== fileNameToRemove);
  yield put(actions.set({ repoAttachments: result }));
  if (action.payload.onSuccess) {
    yield action.payload.onSuccess();
  }
}

function* deleteAttachmentFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] }));
}

// TODO Устранить дублирование с operation-data
function* getRepoAttachments(action: TODO_ANY) {
  try {
    // yield put(actions.set({ isLoading: true }));
    const operationDataId = action.payload.operationDataId;
    const url: string = convertPathUsingParams(apiEndpoints.financialBlock.getRepoAttachments, {
      operationDataId,
    });
    const response = yield new_api.get(url);
    const result = response.data;
    if (result.status === 'OK') {
      yield put(actions.getRepoAttachmentsSuccessful({ result: result.data, operationDataId }));
    } else {
      yield put(actions.getRepoAttachmentsFailure({ error: result }));
    }
  } catch (error) {
    console.error(`//sagas/getRepoAttachments ERROR: error = ${JSON.stringify(error)}`);
    yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload) }));
  } finally {
    // yield put(actions.set({ isLoading: false }));
  }
}

function* getRepoAttachmentsSuccessful(action: TODO_ANY) {
  const uploadedRepoAttachments: TRepoAttachment[] = action.payload.result.filter(
    (item: TRepoAttachment) => item.type === 'UPLOADED'
  );
  const generatedRepoAttachments: TRepoAttachment[] = action.payload.result.filter(
    (item: TRepoAttachment) => item.type === 'GENERATED'
  );

  yield put(
    actions.set({
      uploadedRepoAttachments,
      generatedRepoAttachments,
    })
  );
}
function* getRepoAttachmentsFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] }));
}

// TODO Устранить дублирование
function* generateFileForDownload(action: TODO_ANY) {
  try {
    const url = `${convertPathUsingParams(apiEndpoints.financialBlock.postGenerateFileForDownload, {
      operationDataId: action.payload.operationDataId,
    })}?file_type_code=${action.payload.fileTypeCode}`;
    const response: { data: any } = yield new_api.post(url, { responseType: 'blob' });
    if (response.data.status === 'OK') {
      yield put(
        actions.generateFileForDownloadSuccessful({
          data: response.data.data,
          operationDataId: action.payload.operationDataId,
          fileTypeCode: action.payload.fileTypeCode,
          onSuccess: action.payload.onSuccess,
        })
      );
    } else {
      yield put(actions.generateFileForDownloadFailure({}));
    }
  } catch (error) {
    console.error(
      `//sagas/financial-block/generateFileForDownload ERROR: error = ${JSON.stringify(error)}`
    );
    yield put(actions.generateFileForDownloadFailure({}));
  }
}

function* generateFileForDownloadSuccessful(action: TODO_ANY) {
  const onSuccess = action.payload.onSuccess;
  const downloadUrl = `${convertPathUsingParams(
    apiEndpoints.financialBlock.getGeneratedFileDownload,
    {
      operationDataId: action.payload.operationDataId,
      operationAttachmentId: action.payload.data.operation_attachment_id,
    }
  )}`;
  if (onSuccess) yield onSuccess(downloadUrl, action.payload.data.attachment_file_name);
}

function* generateFileForDownloadFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] }));
}

function onGenerateFileForDownloadSuccess(payload: TODO_ANY) {
  payload.onSuccess && payload.onSuccess();
}

function* generateDocuments(action: TODO_ANY) {
  const generatedFiles: TGeneratedFile[] = [];
  const fileTypeCode = 'TABX';
  try {
    yield put(
      actions.generateFileForDownload({
        operationDataId: action.payload.operationDataId,
        fileTypeCode,
        onSuccess: (downloadUrl: string, attachmentFileName: string) => {
          generatedFiles.push({ fileTypeCode, downloadUrl, attachmentFileName });
          if (generatedFiles.length === 2) {
            onGenerateFileForDownloadSuccess({
              onSuccess: action.payload.onSuccess,
            });
          }
        },
      })
    );
    yield put(
      actions.generateFileForDownload({
        operationDataId: action.payload.operationDataId,
        fileTypeCode: 'ENQ',
        onSuccess: (downloadUrl: string, attachmentFileName: string) => {
          generatedFiles.push({ fileTypeCode: 'ENQ', downloadUrl, attachmentFileName });
          if (generatedFiles.length === 2) {
            onGenerateFileForDownloadSuccess({
              onSuccess: action.payload.onSuccess,
            });
          }
        },
      })
    );
  } catch (error) {
    console.error(`//sagas/generateDocuments ERROR: error = ${JSON.stringify(error)}`);
    yield put(actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] }));
  }
}

function* generateDocumentsSuccessful(action: TODO_ANY) {
  const fileTypeCode = action.payload.fileTypeCode;
  const onSuccess = action.payload.onSuccess;
  const downloadUrl = `${convertPathUsingParams(
    apiEndpoints.financialBlock.getGeneratedFileDownload,
    {
      operationDataId: action.payload.operationDataId,
      operationAttachmentId: action.payload.data.operation_attachment_id,
    }
  )}`;
  if (onSuccess) yield onSuccess(downloadUrl, action.payload.data.attachment_file_name);
}

function* saveAndSendForApproval(action: TODO_ANY) {
  const url: string = convertPathUsingParams(apiEndpoints.financialBlock.checkLimitRest, {
    operationDataId: action.payload.operationDataId,
  });
  const result = yield new_api.get(url);
  if (result.data.status === LimitStatus.OK) {
    yield put(actions.saveAndSendForApprovalSuccessful({ onSuccess: action.payload.onSuccess }));
  } else if (result.data.status === LimitStatus.LIMIT_EXCEEDED) {
    yield put(actions.set({ isLimitRequestChangeNeed: true }));
  } else if (result.data.status === LimitStatus.ID_NOT_FOUND) {
    yield put(actions.saveAndSendForApprovalFailure({}));
  }
}

function* saveAndSendForApprovalSuccessful(action: TODO_ANY) {
  if (action.payload.onSuccess) {
    yield action.payload.onSuccess();
  }
}

function* saveAndSendForApprovalFailure(action: TODO_ANY) {
  // yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload) }));
  yield put(actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] }));
}

function* repoRequestApply(action: TODO_ANY) {
  const state: {
    'financial-block': { operationDataId: number };
  } = yield select();
  const operationDataId = state['financial-block'].operationDataId;

  const url: string = convertPathUsingParams(apiEndpoints.financialBlock.repoRequestApply, {
    operationDataId,
  });
  const result = yield new_api.post(url, {});
  if (result.data.status === RepoRequestApplyStatus.OK) {
    yield put(actions.repoRequestApplySuccessful({ onSuccess: action.payload.onSuccess }));
  } else if (result.data.status === RepoRequestApplyStatus.LIMIT_EXCEEDED) {
    yield put(actions.set({ isLimitRequestChangeNeed: true }));
  } else if (result.data.status === RepoRequestApplyStatus.ID_NOT_FOUND) {
    yield put(actions.repoRequestApplyFailure({}));
  }
}

function* repoRequestApplySuccessful(action: TODO_ANY) {
  notification.info(
    'Запрос на оформление заявки отправлен на согласование. Дальнейший статус по работе с заявкой отображается в Журнале операций.'
  );
  yield put(
    actions.set({
      isRepoApplyCompleted: true,
      operationDataId: 0, // TODO Сделать через сброс?
      uploadedRepoAttachments: [], // TODO Сделать через сброс?
    })
  );
  action.payload.onSuccess && action.payload.onSuccess();
}

function* repoRequestApplyFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] }));
}

function* checkSupplierDocDate(action: TODO_ANY) {
  const { repoRequestTypeCode, supplierDocDate, brandCode, operationDataId } = action.payload;

  try {
    let url: string = apiEndpoints.financialBlock.getCheckSupplierDocDate + '?';
    url += `repo_request_type_code=${repoRequestTypeCode}&supplier_doc_date=${supplierDocDate}`;
    url += brandCode ? `&brand_code=${brandCode}` : '';
    url += operationDataId ? `&operation_data_id=${operationDataId}` : '';
    const response: { data: any } = yield new_api.get(url);
    if (response.data.status === 'OK') {
      const result = response.data;
      yield put(
        actions.set({
          supplierDocDateStatus: result.data.check_status,
          supplierDocDateAddPercent: result.data.add_percent,
        })
      );
    } else {
      yield put(
        actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] })
      );
    }
  } catch (error) {
    console.error(`//sagas/checkSupplierDocDate ERROR: error = ${JSON.stringify(error)}`);
    if (!error.response?.data[0]?.message.includes('must not be in the future')) {
      notification.info(`Что-то пошло не так, обратитесь в поддержку`);
    }
    yield put(actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] }));
  }
}

const sagas = function* () {
  yield takeEvery(actions.getRequestCodeOptions, getRequestCodeOptions);
  yield takeEvery(actions.getRequestCodeOptionsSuccessful, getRequestCodeOptionsSuccessful);
  yield takeEvery(actions.getRequestCodeOptionsFailure, getRequestCodeOptionsFailure);

  yield takeEvery(actions.getBrandOptions, getBrandOptions);
  yield takeEvery(actions.getBrandOptionsSuccessful, getBrandOptionsSuccessful);

  yield takeEvery(actions.getBrandModelOptions, getBrandModelOptions);
  yield takeEvery(actions.getBrandModelOptionsSuccessful, getBrandModelOptionsSuccessful);

  yield takeEvery(actions.createRequestData, createRequestData);
  yield takeEvery(actions.createRequestDataUsed, createRequestDataUsed);
  yield takeEvery(actions.createRequestDataSuccessful, createRequestDataSuccessful);
  yield takeEvery(actions.createRequestDataFailure, createRequestDataFailure);

  yield takeEvery(actions.updateRequestData, updateRequestData);
  yield takeEvery(actions.updateRequestDataUsed, updateRequestDataUsed);
  yield takeEvery(actions.updateRequestDataSuccessful, updateRequestDataSuccessful);
  yield takeEvery(actions.updateRequestDataFailure, updateRequestDataFailure);

  yield takeEvery(actions.deleteRequestData, deleteRequestData);
  yield takeEvery(actions.deleteRequestDataUsed, deleteRequestDataUsed);
  yield takeEvery(actions.deleteRequestDataSuccessful, deleteRequestDataSuccessful);

  yield takeEvery(actions.getRequestData, getRequestData);
  yield takeEvery(actions.getRequestDataSuccessful, getRequestDataSuccessful);
  yield takeEvery(actions.getRequestDataFailure, getRequestDataFailure);

  yield takeEvery(actions.getRequestDataUsed, getRequestDataUsed);
  yield takeEvery(actions.getRequestDataUsedSuccessful, getRequestDataUsedSuccessful);

  yield takeEvery(actions.getDealerBrandCodes, getDealerBrandCodes);
  yield takeEvery(actions.getDealerBrandCodesSuccessful, getDealerBrandCodesSuccessful);
  yield takeEvery(actions.getDealerBrandCodesFailure, getDealerBrandCodesFailure);

  yield takeEvery(actions.docRequest, docRequest);
  yield takeEvery(actions.docRequestSuccessful, docRequestSuccessful);
  yield takeEvery(actions.docRequestFailure, docRequestFailure);

  yield takeEvery(actions.tranchesRequest, tranchesRequest);
  yield takeEvery(actions.tranchesRequestSuccessful, tranchesRequestSuccessful);
  yield takeEvery(actions.tranchesRequestFailure, tranchesRequestFailure);

  yield takeEvery(actions.getRequestFileBody, getRequestFileBody);
  yield takeEvery(actions.docDownload, docDownload);
  yield takeEvery(actions.docDownloadUsed, docDownloadUsed);

  yield takeEvery(actions.attachmentDownload, attachmentDownload);

  yield takeEvery(actions.getUsedCarSettings, getUsedCarSettings);

  // РЕПО
  yield takeEvery(actions.getRepoRequestData, getRepoRequestData);
  yield takeEvery(actions.getRepoRequestDataSuccessful, getRepoRequestDataSuccessful);
  yield takeEvery(actions.getRepoRequestDataFailure, getRepoRequestDataFailure);

  yield takeEvery(actions.getRepoRequestDataById, getRepoRequestDataById);
  yield takeEvery(actions.getRepoRequestDataByIdSuccessful, getRepoRequestDataByIdSuccessful);
  yield takeEvery(actions.getRepoRequestDataByIdFailure, getRepoRequestDataByIdFailure);

  yield takeEvery(actions.createRepoRequestData, createRepoRequestData);
  yield takeEvery(actions.createRepoRequestDataSuccessful, createRepoRequestDataSuccessful);
  yield takeEvery(actions.createRepoRequestDataFailure, createRepoRequestDataFailure);

  yield takeEvery(actions.deleteRepoRequestDataById, deleteRepoRequestDataById);
  yield takeEvery(actions.deleteRepoRequestDataByIdSuccessful, deleteRepoRequestDataByIdSuccessful);

  yield takeEvery(actions.updateRepoRequestData, updateRepoRequestData);
  yield takeEvery(actions.updateRepoRequestDataSuccessful, updateRepoRequestDataSuccessful);
  yield takeEvery(actions.updateRepoRequestDataFailure, updateRepoRequestDataFailure);

  yield takeEvery(actions.getRepoRequestTypes, getRepoRequestTypes);
  yield takeEvery(actions.getRepoRequestTypesSuccessful, getRepoRequestTypesSuccessful);
  yield takeEvery(actions.getRepoRequestTypesFailure, getRepoRequestTypesFailure);

  yield takeEvery(actions.getRepoRequestDataBrands, getRepoRequestDataBrands);
  yield takeEvery(actions.getRepoRequestDataBrandsSuccessful, getRepoRequestDataBrandsSuccessful);
  yield takeEvery(actions.getRepoRequestDataBrandsFailure, getRepoRequestDataBrandsFailure);

  yield takeEvery(actions.prepareRepoFinancing, prepareRepoFinancing);

  yield takeEvery(actions.getRepoRequestDataFinancingInfo, getRepoRequestDataFinancingInfo);
  yield takeEvery(
    actions.getRepoRequestDataFinancingInfoSuccessful,
    getRepoRequestDataFinancingInfoSuccessful
  );
  yield takeEvery(
    actions.getRepoRequestDataFinancingInfoFailure,
    getRepoRequestDataFinancingInfoFailure
  );

  // yield takeEvery(actions.setpriceWithVAT, setpriceWithVAT);

  yield takeEvery(actions.uploadForRepo, uploadForRepo);
  yield takeEvery(actions.uploadForRepoSuccessful, uploadForRepoSuccessful);
  yield takeEvery(actions.uploadForRepoFailure, uploadForRepoFailure);

  yield takeEvery(actions.getRepoAttachments, getRepoAttachments);
  yield takeEvery(actions.getRepoAttachmentsSuccessful, getRepoAttachmentsSuccessful);
  yield takeEvery(actions.getRepoAttachmentsFailure, getRepoAttachmentsFailure);

  yield takeEvery(actions.addAttachments, addAttachments);

  yield takeEvery(actions.deleteAttachment, deleteAttachment);
  yield takeEvery(actions.deleteAttachmentSuccessful, deleteAttachmentSuccessful);
  yield takeEvery(actions.deleteAttachmentFailure, deleteAttachmentFailure);

  yield takeEvery(actions.generateFileForDownload, generateFileForDownload);
  yield takeEvery(actions.generateFileForDownloadSuccessful, generateFileForDownloadSuccessful);
  yield takeEvery(actions.generateFileForDownloadFailure, generateFileForDownloadFailure);

  yield takeEvery(actions.generateDocuments, generateDocuments);
  yield takeEvery(actions.generateDocumentsSuccessful, generateDocumentsSuccessful);

  yield takeEvery(actions.saveAndSendForApproval, saveAndSendForApproval);
  yield takeEvery(actions.saveAndSendForApprovalSuccessful, saveAndSendForApprovalSuccessful);
  yield takeEvery(actions.saveAndSendForApprovalFailure, saveAndSendForApprovalFailure);

  yield takeEvery(actions.repoRequestApply, repoRequestApply);
  yield takeEvery(actions.repoRequestApplySuccessful, repoRequestApplySuccessful);
  yield takeEvery(actions.repoRequestApplyFailure, repoRequestApplyFailure);

  yield takeEvery(actions.checkSupplierDocDate, checkSupplierDocDate);
};

export default sagas;

function onError(message: string) {
  console.log(message);
  //notification.err(message);
  put(actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] }));
  //  notification.info(message);
}
