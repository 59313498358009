// Устранить дублирование с admin
type StatusCode =
  | 'PASSWORD_NO_MATCH'
  | 'PASSWORD_INVALID'
  | 'PASSWORD_INVALID_BLOCKED'
  | 'PASSWORD_SHORT'
  | 'PASSWORD_FREQUENT'
  | 'PASSWORD_REUSE_FORBIDDEN';

const statusMessages: Record<StatusCode, string> = {
  PASSWORD_NO_MATCH: 'Пароли не совпадают',
  PASSWORD_INVALID: 'Неверный логин или пароль',
  PASSWORD_INVALID_BLOCKED: 'Неверный логин или пароль, учётная запись заблокирована',
  PASSWORD_SHORT: 'Слишком короткий пароль',
  PASSWORD_FREQUENT: 'Пароль меняется слишком часто',
  PASSWORD_REUSE_FORBIDDEN: 'Пароль уже использовался',
};

export function getMessageByStatusCode(code: StatusCode): string {
  return statusMessages[code];
}
