var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var LockChecked = function (props) { return (React.createElement("svg", __assign({}, props, { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }),
    React.createElement("rect", { x: "6.75", y: "13.75", width: "14.5", height: "9.5", stroke: "black", strokeWidth: "1.5" }),
    React.createElement("path", { d: "M9.75 14.5V8.75C9.75 6.40279 11.6528 4.5 14 4.5V4.5C16.3472 4.5 18.25 6.40279 18.25 8.75V14.5", stroke: "black", strokeWidth: "1.5" }),
    React.createElement("path", { d: "M12 16.5L16 20.5M16 16.5L12 20.5", stroke: "black", strokeWidth: "1.5" }))); };
export default LockChecked;
