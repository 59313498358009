import { put, takeEvery } from 'redux-saga/effects';
import new_api from 'utils/new-api';
import storage from 'utils/storage';

import { convertApiErrorCodesToMessages } from 'helpers/app';

import apiEndpoints from 'configs/api/endpoints';
import routingConfig from 'configs/routing';

import { IDTOVariableRequisitesResponse } from 'dto/variable';

import { actions } from './index';

import { convertPathUsingParams } from '@rfb/common';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import notification from '../../../utils/notification';
import { dealerSystemsKey } from '../../auth/store';

function* getContactsText() {
  try {
    const dealerSystemConfig = storage.get(dealerSystemsKey).data;
    if (dealerSystemConfig.rb_flag === 1 || dealerSystemConfig.rf_flag === 1) {
      const response = yield new_api.get(apiEndpoints.variable.contacts);
      const resultBody = JSON.parse(response.data?.data);
      yield put(actions.set({ rbContactsText: resultBody.text }));
    }
    if (dealerSystemConfig.rf_flag === 1) {
      const response = yield new_api.get(apiEndpoints.variable.contacts_rf);
      const resultBody = JSON.parse(response.data?.data);
      yield put(actions.set({ rfContactsText: resultBody.text }));
    }
  } catch (error) {
    yield put(actions.getContactsTextFailure(error.response?.data));
  }
}

function* getContactsTextFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: action.payload?.message }));
}

function* getReminderText() {
  try {
    const dealerSystemConfig = storage.get(dealerSystemsKey).data;
    if (dealerSystemConfig.rb_flag === 1) {
      const response = yield new_api.get(apiEndpoints.variable.reminder);
      const resultBody = JSON.parse(response.data?.data);
      yield put(actions.set({ rbReminderText: resultBody.text }));
    }
    if (dealerSystemConfig.rf_flag === 1) {
      const response = yield new_api.get(apiEndpoints.variable.reminder_rf);
      const resultBody = JSON.parse(response.data?.data);
      yield put(actions.set({ rfReminderText: resultBody.text }));
    }
  } catch (error) {
    yield put(actions.getReminderTextFailure(error.response?.data));
  }
}

function* getReminderTextFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: action.payload?.message }));
}

function* getTariffsText() {
  try {
    const response = yield new_api.get(apiEndpoints.variable.tariffs);
    const resultBody = JSON.parse(response.data?.data);
    yield put(actions.getTariffsTextSuccessful(resultBody.text));
  } catch (error) {
    yield put(actions.getTariffsTextFailure(error.response?.data));
  }
}

function* getTariffsTextSuccessful(action: TODO_ANY) {
  yield put(actions.set({ tariffsText: action.payload }));
}

function* getTariffsTextFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: action.payload?.message }));
}

function* getRequisitesText() {
  try {
    const response: {
      data: {
        data: IDTOVariableRequisitesResponse;
      };
    } = yield new_api.get(apiEndpoints.variable.requisites);
    const result = response.data;
    yield put(actions.getRequisitesTextSuccessful(result.data.text));
  } catch (error) {
    yield put(actions.getRequisitesTextFailure(error.response?.data));
  }
}

function* getRequisitesTextSuccessful(action: TODO_ANY) {
  yield put(actions.set({ requisitesText: action.payload }));
}

function* getRequisitesTextFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: action.payload?.message }));
}

function* sendFeedback(action: TODO_ANY) {
  try {
    yield put(actions.set({ isFeedbackSending: true }));
    yield new_api.post(apiEndpoints.feedback.create, action.payload.data);
    yield put(
      actions.sendFeedbackSuccessful({
        history: action.payload.history,
        notificationDealerSystem: action.payload.notificationDealerSystem,
      })
    );
  } catch (error) {
    yield put(actions.sendFeedbackFailure(error.response?.data));
  } finally {
    yield put(actions.set({ isFeedbackSending: false }));
  }
}

function* sendFeedbackSuccessful(action: TODO_ANY) {
  yield put(actions.set({ isFeedbackSent: true }));
  if (!!action.payload.notificationDealerSystem) {
    notification.info('Ваше обращение успешно отправлено');
    const notificationBackPath = convertPathUsingParams(routingConfig.notifications.path, {
      dealerSystem: action.payload.notificationDealerSystem,
    });
    action.payload.history.push(notificationBackPath);
  } else {
    action.payload.history.push(routingConfig.feedbackSent.path);
  }
}

function* sendFeedbackFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload) }));
}

export default function* () {
  yield takeEvery(actions.getContactsText, getContactsText);
  yield takeEvery(actions.getContactsTextFailure, getContactsTextFailure);

  yield takeEvery(actions.getReminderText, getReminderText);
  yield takeEvery(actions.getReminderTextFailure, getReminderTextFailure);

  yield takeEvery(actions.getTariffsText, getTariffsText);
  yield takeEvery(actions.getTariffsTextSuccessful, getTariffsTextSuccessful);
  yield takeEvery(actions.getTariffsTextFailure, getTariffsTextFailure);

  yield takeEvery(actions.getRequisitesText, getRequisitesText);
  yield takeEvery(actions.getRequisitesTextSuccessful, getRequisitesTextSuccessful);
  yield takeEvery(actions.getRequisitesTextFailure, getRequisitesTextFailure);

  yield takeEvery(actions.sendFeedback, sendFeedback);
  yield takeEvery(actions.sendFeedbackSuccessful, sendFeedbackSuccessful);
  yield takeEvery(actions.sendFeedbackFailure, sendFeedbackFailure);
}
