import { Directions, IDTOHeaders, convertPathUsingParams } from '@rfb/common';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import apiEndpoints from 'configs/api/endpoints';
import fp from 'lodash/fp';
import { actions as appActions } from 'modules/app/store';
import { put, takeEvery } from 'redux-saga/effects';
import new_api from 'utils/new-api';
import {
  TDTONotificationResponse as TDTONotificationData,
  TDTONotificationResponse,
} from '../../../dto/notification';
import { NotificationReadStatusFilterCode } from '../configs/type';
import { INotificationState, actions } from './index';

// TODO Устранить дублирование с admin//sagas.ts

function* getNotifications(action: TODO_ANY) {
  const filter: INotificationState['filter'] = action.payload.filter;
  yield put(actions.setError({ api: [] }));
  try {
    yield put(actions.set({ isLoading: true }));
    const defaultSort: string = '-notification_date';
    const sortParameter: string =
      filter.sorting && filter.sorting.value
        ? `${fp.isEqual(Directions.DESC, filter.sorting?.direction) ? '-' : ''}${
            filter.sorting?.value
          }`
        : defaultSort;
    const url: string =
      apiEndpoints.notifications.getNotifications +
      `?page=${filter.page}` +
      `&sort=${sortParameter}` +
      `&direction=${filter.inOutDirection}` +
      `&sender=${action.payload.system.toUpperCase()}` +
      (filter.subject ? `&subject=${filter.subject}` : '') +
      (filter.statusCode && filter.statusCode !== NotificationReadStatusFilterCode.ALL
        ? `&read=${filter.statusCode}`
        : '') +
      `&read_by=${filter.read_by}` +
      `&per-page=${filter.perPage}`;
    const response = yield new_api.get(url);
    const result = response.data;
    if (result.status === 'OK') {
      const data: TDTONotificationData = result.data;
      yield put(actions.getNotificationsSuccessful({ data, headers: response.headers }));
    } else {
      yield put(actions.getNotificationsFailure(result.data));
    }
  } catch (error) {
    yield put(actions.getNotificationsFailure(error.response?.data));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* getNotificationsSuccessful(action: TODO_ANY) {
  yield put(
    actions.set({
      notifications: action.payload.data,
      dataFilter: action.payload.data.filters,
      pageCount: fp.toNumber(action.payload.headers['x-pagination-page-count']),
    })
  );
}

function* getNotificationsFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: [action.payload.message] }));
}

function* getNotificationById(action: TODO_ANY) {
  console.log(`getNotificationById BEGIN: action.payload = `, action.payload);
  try {
    yield put(actions.setError({ api: [] }));
    yield put(actions.set({ isLoading: true }));
    const url: string = convertPathUsingParams(apiEndpoints.notifications.getNotificationById, {
      id: action.payload.id,
    });

    const response: {
      data: TODO_ANY;
      headers: IDTOHeaders;
    } = yield new_api.get(url);
    console.log(`getNotificationById: response = `, response);
    const result: TDTONotificationResponse = response.data;
    console.log(`getNotificationById: result = `, result);
    if (result.status === 'OK') {
      yield put(actions.getNotificationByIdSuccessful({ data: result.data }));
    } else {
      onError(`Ошибка запроса ${url}: ${JSON.stringify(result.status)}`);
    }
  } catch (error) {
    console.log(`getNotificationById ERROR: `, error);
    yield put(actions.getNotificationByIdFailure(error.response?.data));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
  console.log(`getNotificationById END`);
}

function* getNotificationByIdSuccessful(action: TODO_ANY) {
  console.log(`getNotificationByIdSuccessful BEGIN: action.payload = `, action.payload);
  console.log(`getNotificationByIdSuccessful BEGIN: action.payload.data = `, action.payload.data);
  yield put(actions.set({ currentNotification: action.payload.data }));
}

function* getNotificationByIdFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: [action.payload.message] }));
}

function* setNotificationRead(action: TODO_ANY) {
  try {
    yield put(actions.setError({ api: [] }));
    const url: string = convertPathUsingParams(apiEndpoints.notifications.setNotificationRead, {
      id: action.payload.id,
    });

    const response: {
      data: TODO_ANY;
      headers: IDTOHeaders;
    } = yield new_api.post(url, action.payload.data);
    const result: TDTONotificationData = response.data;
    if (result.status !== 'OK') {
      onError(`Ошибка запроса ${url}: ${JSON.stringify(result.status)}`);
    }
  } catch (error) {
    yield put(actions.setError({ api: [error.response.data.message] }));
  }
}

// TODO Устранить дублирование с operation-data и c admin
const extractFilenameFromContentDisposition = (disposition: string) => {
  let filename = '';
  if (disposition && disposition.startsWith('attachment')) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
};

// TODO Устранить дублирование с operation-data
// TODO response.headers['content-disposition'] здесь возвращает undefined. Почему?
function* documentumFileDownload(action: TODO_ANY) {
  yield put(actions.setError({ api: [] }));
  const endpoint = apiEndpoints.notifications.documentumFileDownload;
  const url: string = convertPathUsingParams(endpoint, { id: action.payload.id });
  const config = {
    responseType: 'blob',
  };
  try {
    const response = yield new_api.get(url, config);
    const contentDisposition = response.headers['content-disposition'];
    const filename = extractFilenameFromContentDisposition(contentDisposition);
    const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = action.payload.filename ?? filename; // TODO Разобраться с filename = undefined
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error(`//sagas/documentumFileDownload ERROR: ${JSON.stringify(error)}`);
    yield put(
      actions.setError({ api: ['Документ недоступен. Попробуйте повторить попытку позднее'] })
    );
  }
}

function* resetNotificationCounters() {
  yield put(appActions.resetNotificationCounters());
}

function replaceBaseUrl(originalUrl: string, newBaseUrl: string): string {
  const url = new URL(originalUrl);
  const newUrl = new URL(url.pathname + url.search + url.hash, newBaseUrl);
  return newUrl.toString();
}

function* docListFileDownload(action: TODO_ANY) {
  yield put(actions.setError({ api: [] }));
  const url: string = replaceBaseUrl(action.payload.href, process.env.REACT_APP_API_URL!);
  const config = {
    responseType: 'blob',
  };
  // Вынести в общее место, устранить дублирование
  try {
    const response = yield new_api.get(url, config);
    const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = fileUrl;
    const bankName = action.payload.id === 'rf' ? 'Русфинанс' : 'Т-Бизнес Авто';
    link.download = `Список документов предоставляемых в ${bankName}.docx`;
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    yield put(
      actions.setError({ api: ['Документ недоступен. Попробуйте повторить попытку позднее'] })
    );
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
  yield takeEvery(actions.getNotifications, getNotifications);
  yield takeEvery(actions.getNotificationsSuccessful, getNotificationsSuccessful);
  yield takeEvery(actions.getNotificationsFailure, getNotificationsFailure);

  yield takeEvery(actions.getNotificationById, getNotificationById);
  yield takeEvery(actions.getNotificationByIdSuccessful, getNotificationByIdSuccessful);
  yield takeEvery(actions.getNotificationByIdFailure, getNotificationByIdFailure);

  yield takeEvery(actions.setNotificationRead, setNotificationRead);

  yield takeEvery(actions.documentumFileDownload, documentumFileDownload);
  yield takeEvery(actions.resetNotificationCounters, resetNotificationCounters);

  yield takeEvery(actions.docListFileDownload, docListFileDownload);
}

// TODO Устранить дублирование в сагах
function onError(message: string) {
  console.log(message);
  put(actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] }));
}
