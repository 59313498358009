import { put, select, takeEvery } from 'redux-saga/effects';

import new_api from 'utils/new-api';

import apiEndpoints from 'configs/api/endpoints';

import { actions as userActions } from 'modules/user/store';
import { actions, selectors } from './index';

import { IDTOVariableInstructionResponse } from 'dto/variable';

import { IDTOHeaders, Logger, TClientLogSettings } from '@rfb/common';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { IMenuElement, IMenuSubMenu } from '@rfb/ui-kit';
import { TGetCountAgreeRequestResponse } from 'dto/operation-data';
import notification from 'utils/notification';
import storage from 'utils/storage';
import { TDTONotificationCountResponse } from '../../../dto/notification';
import { dealerSystemsKey } from '../../auth/store';

export const readOnlyRoleKey: string = 'READ_ONLY_ROLE';
export const dealerDiasoftIdKey: string = 'DEALER_DIASOFT_ID';

function* checkSystemAvailability(): TODO_ANY {
  try {
    const response = yield new_api.get(apiEndpoints.system.status);
    const result = response.data;
    const isSystemAvailable = result.data.available;
    yield put(actions.set({ isSystemAvailable: isSystemAvailable }));
    if (!isSystemAvailable) {
      yield put(actions.set({ systemUnavailabilityMessage: result.data.message }));
    }
  } catch (error) {
    yield put(actions.set({ isSystemAvailable: false }));
  }
}

function* init(): TODO_ANY {
  console.log(`//sagas/init BEGIN`);
  yield put(actions.set({ isLoading: true }));
  try {
    const profile = yield new_api.get(apiEndpoints.profile.view);
    const dealer = yield new_api.get(apiEndpoints.rfInfo.info);
    yield put(userActions.set({ profile: profile.data }));
    yield put(userActions.set({ dealer: dealer.data }));
    storage.set(readOnlyRoleKey, profile.data.read_only);
    storage.set(dealerDiasoftIdKey, dealer.data.diasoft_id);
    // Инициализация клиентского логирования
    yield put(
      actions.getClientLogSettings({
        onSuccess: (clientLogSettings: TClientLogSettings) => {
          const logger = Logger.getInstance();
          const currentLogin = profile.data.email;
          if (clientLogSettings?.login?.toLowerCase() === currentLogin.toLowerCase()) {
            // Настройки применяются только к текущему пользователю
            logger.setMaxLogSize(clientLogSettings.logSize);
            logger.setLogLevel(clientLogSettings.logLevel);
          }
          logger.info('Start client application');
        },
      })
    );
    yield put(actions.initSuccessful());
  } catch (error) {
    yield put(actions.initFailure(error.response?.data));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
  console.log(`//sagas/init END`);
}

function* initSuccessful() {
  yield put(actions.set({ isAppInit: true }));
}

function* initFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: action.payload?.message }));
  yield put(actions.set({ apiErrorCode: action.payload?.code }));
}

function* getHelpText() {
  try {
    const response: {
      data: {
        data: IDTOVariableInstructionResponse;
      };
    } = yield new_api.get(apiEndpoints.variable.instruction);
    const result = response.data;
    yield put(actions.getHelpTextSuccessful(result.data.text));
  } catch (error) {
    yield put(actions.getHelpTextFailure(error.message));
  }
}

function* getHelpTextSuccessful(action: TODO_ANY) {
  yield put(actions.set({ helpText: action.payload }));
}

function* getHelpTextFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: action.payload }));
}

export const OPERATION_DATA_MENU_INDEX = 2;
export const MAIL_MENU_INDEX = 5;
export const RB_MENU_ITEM_INDEX = 0;
export const RF_MENU_ITEM_INDEX = 1;

function updateMailMenu(
  appMenuConfig: (IMenuElement | IMenuSubMenu)[],
  rb_count: number,
  rf_count: number
): (IMenuElement | IMenuSubMenu)[] {
  console.log(`updateMailMenu BEGIN`);
  const newAppMenuConfig: (IMenuElement | IMenuSubMenu)[] = [...appMenuConfig];
  newAppMenuConfig[MAIL_MENU_INDEX] = {
    title: 'Почта',
    // type: MenuItemType.NOTIFICATION,
    count: rb_count + rf_count,
  };
  const dealerSystem = storage.get(dealerSystemsKey);
  console.log(`updateMailMenu: dealerSystem`, dealerSystem);
  const dealerSystemConfig = dealerSystem.data;
  console.log(`updateMailMenu: dealerSystemConfig`, dealerSystemConfig);

  if (dealerSystemConfig.rf_flag === 1) {
    // @ts-ignore
    newAppMenuConfig[MAIL_MENU_INDEX].items = [
      { title: 'Т-Бизнес Авто', link: '/notifications/rb', count: rb_count },
      { title: 'РУСФИНАНС', link: '/notifications/rf', count: rf_count },
    ];
  } else {
    // @ts-ignore
    newAppMenuConfig[MAIL_MENU_INDEX].link = '/notifications/rb';
  }
  console.log(`updateMailMenu END: newAppMenuConfig`, newAppMenuConfig);
  return newAppMenuConfig;
}

function updateOperationDataMenu(
  appMenuConfig: (IMenuElement | IMenuSubMenu)[],
  requestCount: number
) {
  const newAppMenuConfig: (IMenuElement | IMenuSubMenu)[] = [...appMenuConfig];
  newAppMenuConfig[OPERATION_DATA_MENU_INDEX] = {
    title: 'Журнал операций',
    // link: '/operation-data/rf',
    link: '/operation-data',
    count: requestCount,
  };
  return newAppMenuConfig;
}

function* getClientLogSettings(action: TODO_ANY) {
  console.log(
    `//sagas/getClientLogSettings BEGIN: action.payload = ${JSON.stringify(action.payload)}`
  );
  try {
    const response = yield new_api.get(apiEndpoints.clientLog.getClientLogSettings);
    const resultBody = JSON.parse(response.data?.data);
    const clientLogSettings: TClientLogSettings = {
      logSize: resultBody.logSize,
      logLevel: parseInt(resultBody.logLevel),
      login: resultBody.login,
    };
    action.payload.onSuccess && action.payload.onSuccess(clientLogSettings);
  } catch (error) {
    console.log(`//sagas/getClientLogSettings ERROR: ${JSON.stringify(error)}`);
    throw { error, actions };
  }
  console.log(`//sagas/getClientLogSettings END`);
}

function* postClientLog(action: TODO_ANY) {
  console.log(`//sagas/postClientLog BEGIN: action.payload = ${JSON.stringify(action.payload)}`);
  try {
    const logger = Logger.getInstance();
    const strLog: any = logger.getLogs().join('\n');
    yield new_api.post(apiEndpoints.clientLog.postClientLog, strLog, {
      headers: {
        'Content-Type': 'text/plain',
      },
    });
    notification.info('Лог успешно отправлен');
  } catch (error) {
    notification.err('Ошибка отправки лога');
    throw { error, actions };
  }
  console.log(`//sagas/postClientLog END`);
}

function* getNotificationCount(action: TODO_ANY) {
  console.log(`//sagas/getNotificationCount BEGIN: `, action.payload);
  const onSuccess = action.payload?.onSuccess;
  const state = yield select();
  const appMenuConfig = selectors.selectAppMenuConfig(state);
  try {
    const url = apiEndpoints.notifications.getNotificationsCountNewApi;
    const response: {
      data: TODO_ANY;
      headers: IDTOHeaders;
    } = yield new_api.get(url);
    const result: TDTONotificationCountResponse = response.data;
    if (result.status === 'OK') {
      console.log(`//sagas/getNotificationCount: result`, result);
      yield put(
        actions.set({
          appMenuConfig: updateMailMenu(appMenuConfig, result.data.rb_count, result.data.rf_count),
        })
      );
      onSuccess && onSuccess();
    } else {
      onError(`Ошибка запроса ${url}: ${JSON.stringify(result.status)}`);
    }
  } catch (error) {
    console.log(`//sagas/getNotificationCount ERROR:`, error);
    yield put(actions.setError({ api: [error.message] }));
  }
  console.log(`//sagas/getNotificationCount END`);
}

function* getCountAgreeRequest(action: TODO_ANY) {
  const onSuccess = action.payload?.onSuccess;
  const state = yield select();
  const appMenuConfig = selectors.selectAppMenuConfig(state);
  try {
    const response: { data: TGetCountAgreeRequestResponse } = yield new_api.get(
      apiEndpoints.operationData.getCountAgreeRequest
    );
    const requestCount = response.data.data.request_count;
    //const requestCount = 3;
    const newAppMenuConfig = updateOperationDataMenu(appMenuConfig, requestCount);
    yield put(
      actions.set({
        requestCount,
        appMenuConfig: newAppMenuConfig,
      })
    );
    onSuccess && onSuccess();
  } catch (error) {
    yield put(actions.setError({ api: [error.message] }));
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
  yield takeEvery(actions.checkSystemAvailability, checkSystemAvailability);

  yield takeEvery(actions.init, init);
  yield takeEvery(actions.initSuccessful, initSuccessful);
  yield takeEvery(actions.initFailure, initFailure);

  yield takeEvery(actions.getHelpText, getHelpText);
  yield takeEvery(actions.getHelpTextSuccessful, getHelpTextSuccessful);
  yield takeEvery(actions.getHelpTextFailure, getHelpTextFailure);

  yield takeEvery(actions.getNotificationCount, getNotificationCount);

  yield takeEvery(actions.getCountAgreeRequest, getCountAgreeRequest);

  yield takeEvery(actions.getClientLogSettings, getClientLogSettings);
  yield takeEvery(actions.postClientLog, postClientLog);
}

// TODO Устранить дублирование в сагах
function onError(message: string) {
  console.log(message);
  put(actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] }));
}
