import { ClientLineTypes } from '../../../client-line/configs/type';
import {
  trancheStatus,
  TrancheStatus,
  TrancheStatusValue,
  trancheStatusValue,
} from '../../configs/status';
import { trancheTypeTranslations } from '../../configs/type';

// TODO Устранить дублирование, обеспечить единообразие с client-line
export const getTransformedNumberForView = (
  type: string,
  number: string,
  agreement_number: string
): string => {
  let result: string;
  switch (type) {
    case ClientLineTypes.DC:
      result = `${agreement_number ? agreement_number : 'Б/H'}`;
      break;
    case ClientLineTypes.DF:
      result = `${agreement_number}`;
      break;
    case ClientLineTypes.CC:
      result = trancheTypeTranslations[type];
      break;
    default:
      result = `${number}-${trancheTypeTranslations[type] ?? ''}`;
      break;
  }
  console.log(`getTransformedNumberForView(${type}, ${number}, ${agreement_number}) = `, result);
  return result;
};

export const getTransformedStatus = (status: TrancheStatus): string => trancheStatusValue[status];

export const getStatusId = (statusValue: TrancheStatusValue): TrancheStatus => {
  return trancheStatus[statusValue];
};
