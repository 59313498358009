import { put, takeEvery } from 'redux-saga/effects';

import new_api from 'utils/new-api';

import { convertApiErrorCodesToMessages } from 'helpers/app';

import apiEndpoints from 'configs/api/endpoints';
import routingConfig from 'configs/routing';

import { actions } from './index';

import { IDTOProfileViewResponse } from 'dto/profile';
import { IDTORFInfoDealerResponse } from 'dto/rf-info';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { getMessageByStatusCode } from 'utils/helpers';

function* getProfile(action: TODO_ANY) {
  try {
    const result: { data: IDTOProfileViewResponse } = yield new_api.get(apiEndpoints.profile.view);
    yield put(actions.getProfileSuccessful(result.data));
  } catch (error) {
    yield put(actions.getProfileFailure(error));
  }
}

function* getProfileSuccessful(action: TODO_ANY) {
  yield put(actions.set({ profile: action.payload }));
}

function* getProfileFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: action.payload?.message }));
}

function* getDealer(action: TODO_ANY) {
  try {
    const result: { data: IDTORFInfoDealerResponse } = yield new_api.get(apiEndpoints.rfInfo.info);
    yield put(actions.getDealerSuccessful(result.data));
  } catch (error) {
    yield put(actions.getDealerFailure(error));
  }
}

function* getDealerSuccessful(action: TODO_ANY) {
  yield put(actions.set({ dealer: action.payload }));
}

function* getDealerFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: action.payload?.message }));
}

function* changePassword(action: TODO_ANY) {
  console.log(`//user//sagas/changePassword BEGIN: action.payload.data = `, action.payload.data);
  try {
    const response = yield new_api.post(apiEndpoints.security.passwordChange, action.payload.data);
    const result = response.data;
    if (result.status === 'OK') {
      yield put(actions.changePasswordSuccessful({ history: action.payload.history }));
    } else {
      const message = getMessageByStatusCode(result.status);
      console.log(`//user//sagas/changePassword: message = `, message);
      yield put(actions.setError({ api: [message] }));
      action.payload.history.push(routingConfig.profile.path);
    }
  } catch (error) {
    console.log(`//user//sagas/changePassword ERROR: error = `, error);
    yield put(
      actions.changePasswordFailure({ error: error.response.data, history: action.payload.history })
    );
    console.log(`//user//sagas/changePassword END`);
  }
}

function* changePasswordSuccessful(action: TODO_ANY) {
  yield put(actions.reset());
  action.payload.history.push(routingConfig.profile.path);
  yield put(actions.getProfile({}));
}

function* changePasswordFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload.error) }));
  action.payload.history.push(routingConfig.profilePasswordChange.path);
  yield;
}

function* changePhoneNumber(action: TODO_ANY) {
  try {
    yield new_api.post(apiEndpoints.profile.phoneNumberChange, action.payload.data);
    yield put(actions.changePhoneNumberSuccessful({ history: action.payload.history }));
  } catch (error) {
    yield put(
      actions.changePhoneNumberFailure({
        data: error.response.data,
        history: action.payload.history,
      })
    );
  }
}

function* changePhoneNumberSuccessful(action: TODO_ANY) {
  yield put(actions.reset());
  action.payload.history.push(routingConfig.profile.path);
  yield put(actions.getProfile({}));
}

function* changePhoneNumberFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload.data) }));
  action.payload.history.push(routingConfig.profilePhoneNumberChange.path);
  yield;
}

function* changeCodeWord(action: TODO_ANY) {
  try {
    yield new_api.post(apiEndpoints.profile.codeWordChange, action.payload.data);
    yield put(actions.changeCodeWordSuccessful({ history: action.payload.history }));
  } catch (error) {
    yield put(
      actions.changeCodeWordFailure({
        data: error.response.data,
        history: action.payload.history,
      })
    );
  }
}

function* changeCodeWordSuccessful(action: TODO_ANY) {
  yield put(actions.reset());
  action.payload.history.push(routingConfig.profile.path);
  yield put(actions.getProfile({}));
}

function* changeCodeWordFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload.data) }));
  action.payload.history.push(routingConfig.profileCodeWordChange.path);
  yield;
}

const sagas = function* () {
  yield takeEvery(actions.getProfile, getProfile);
  yield takeEvery(actions.getProfileSuccessful, getProfileSuccessful);
  yield takeEvery(actions.getProfileFailure, getProfileFailure);

  yield takeEvery(actions.getDealer, getDealer);
  yield takeEvery(actions.getDealerSuccessful, getDealerSuccessful);
  yield takeEvery(actions.getDealerFailure, getDealerFailure);

  yield takeEvery(actions.changePassword, changePassword);
  yield takeEvery(actions.changePasswordSuccessful, changePasswordSuccessful);
  yield takeEvery(actions.changePasswordFailure, changePasswordFailure);

  yield takeEvery(actions.changePhoneNumber, changePhoneNumber);
  yield takeEvery(actions.changePhoneNumberSuccessful, changePhoneNumberSuccessful);
  yield takeEvery(actions.changePhoneNumberFailure, changePhoneNumberFailure);

  yield takeEvery(actions.changeCodeWord, changeCodeWord);
  yield takeEvery(actions.changeCodeWordSuccessful, changeCodeWordSuccessful);
  yield takeEvery(actions.changeCodeWordFailure, changeCodeWordFailure);
};

export default sagas;
